import React, { useEffect, useState } from "react";

import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import ModalAction from "../ModalActions";

import { Creators as AprovarCreators } from "../../store/ducks/aprovarContratos";
import { Creators as GerenteCreators } from "../../store/ducks/gerentePlataforma";
import { Creators as ModalActionCreators } from "../../store/ducks/modalAction";

import { ObjIsEmpty, tipoFinanciamento } from "../../utils/helper";
import { Container, Conteudo } from "./styles";

import Topo from "../topo";
import FiltroContrato from "../filtroContrato";
import Modal from "./tipoModal";

import TipoContrato from "./tipoContrato";
import ModalContentAction from "../modal";

function VincularContratos() {
  const dispatch = useDispatch();
  const history = useHistory();

  const [select, setSelect] = useState([]);
  const [itemSelect, setItemSelect] = useState({});
  const [filter, setFilter] = useState("");
  const [dados, setDados] = useState([]);

  const [dadosTemp, setDadosTemp] = useState([]);

  const [typeAcao, setTypeAcao] = useState("");

  const { dados: data } = useSelector((state) => state.gerentePlataforma);
  const { modal_remover_aprovacao, dados: aprovar } = useSelector(
    (state) => state.modalAction
  );

  const configModalRemover = {
    title: "Atenção",
    description: "Deseja remover o vinculo deste contrato?",
    onClick: removerAprovacaoContrato,
    closeModal: closeModalRemover,
  };

  const {
    location: { itemselect: url },
  } = history;

  if (!url) {
    history.push("/sistema");
  }

  function removerAprovacaoContrato() {
    const { itens, sendStatus, seguro = false } = aprovar;

    dispatch(
      AprovarCreators.request_remover_aprovacao_contrato(
        itens,
        sendStatus,
        seguro
      )
    );
  }

  function closeModalRemover() {
    dispatch(ModalActionCreators.closeModalRemoverAprovacao());
  }

  useEffect(() => {
    if (itemSelect?.label) {
      const type_acao = dados[itemSelect?.label]
        ? dados[itemSelect?.label][0].acao.type
        : "";

      setTypeAcao(type_acao);
      setDadosTemp(dados[itemSelect.label] ? dados[itemSelect.label] : []);
    } else {
      const type_acao = dados[url?.label]
        ? dados[url?.label][0]?.acao?.type
        : "";
      setTypeAcao(type_acao);
      setDadosTemp(dados[url?.label] ? dados[url?.label] : []);
    }
  }, [dados, url, itemSelect, itemSelect.label]);

  useEffect(() => {
    if (!ObjIsEmpty(data)) {
      const keys = Object.keys(data);

      setSelect(
        keys.map((item, key) => {
          return { value: key, label: item };
        })
      );

      //financiamento_valor
      const newDate = keys.map((item) => {
        return {
          [item]: data[item].map((item) => {
            return {
              ...item,
              acao: {
                ...item.acao,
                hasfinanciamento2: tipoFinanciamento.includes(item.acao.type),
                tipo_atual: item.acao.type,
                type: tipoFinanciamento.includes(item.acao.type)
                  ? "financiamento"
                  : item.acao.type,
              },
            };
          }),
        };
      });

      function arrayParaObjeto(array) {
        var objeto = {};
        for (var i = 0; i < array.length; i++) {
          objeto[keys[i]] = array[i][keys[i]];
        }
        return objeto;
      }

      setDados(arrayParaObjeto(newDate));
    }
  }, [data]);

  useEffect(() => {
    dispatch(GerenteCreators.request());
  }, [dispatch]);

  return (
    <>
      <Container>
        <Conteudo>
          <Topo />
          <FiltroContrato
            select={select}
            setItemSelect={setItemSelect}
            placeholder={url?.label}
            handleSearhChange={(filter) => setFilter(filter)}
          />
          <div className="conteudo">
            {typeAcao ? (
              <TipoContrato tipo={typeAcao} dados={dadosTemp} filter={filter} />
            ) : null}
          </div>
        </Conteudo>
      </Container>
      <ModalContentAction
        component={ModalAction}
        active={modal_remover_aprovacao}
        rest={configModalRemover}
      />
      <Modal tipo={typeAcao} />
    </>
  );
}

export default VincularContratos;
