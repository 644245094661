import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Creators as ModalCreators } from "../../store/ducks/modalVinculo";
import { Creators as VinculoCreators } from "../../store/ducks/vinculo";
import { formataCPF } from "../../utils/helper";

import Modal from "../modal";

import ModalVinculo from "../modalVinculo";
import Finalizar from "../modalVinculo/finalizar";

import ModalConfirmaVinculo from "../modalVinculo/confirmaVinculo";

import ModalVinculoSeguro from "../modalVinculoSeguro";
import ModalAction from "../ModalActions/aviso";
import ModalContrato from "../ModalActions/contrato";
import ModalConfirmaVinculoSeguro from "../modalVinculoSeguro/confirmaVinculo";
import ModalVisualiza from "../modalVinculoVisualizar";
import Alerta from "../modalVinculoSeguro/alerta_qtd_vouche";
import { ErroFinalizar } from "../modalVinculo/erroFinalizar";

function Tipomodal(props) {
  const { tipo } = props;
  const dispatch = useDispatch();

  const [gerente, setGerente] = useState([]);
  const [vendedor, setVendedor] = useState([]);
  const [modalConfirCadastroFuncionario, setModalConfirCadastroFuncionario] =
    useState(false);

  const {
    modal_vinculo_vendedor,
    modal_vincular_gerente,
    modal_confirma_vinculo,
    modal_vinculo_finaliza,
    modal_vinculo_visualizar,
    modal_aprovar_vinculo,
    modal_erro_vincular,
    vincular_dados,
  } = useSelector((state) => state.modalVinculo);

  const {
    modal_vinculo_vendedor_seguro,
    modal_confirma_vinculo_seguro,
    modal_alerta_user_qtd_voucher,
  } = useSelector((state) => state.modalVinculoSeguro);

  const { error_message } = useSelector((state) => state.funcionario);

  const { data } = useSelector((state) => {
    return state.funcionario;
  });

  const configCreateFuncionario = {
    title: error_message === "Este funcionário já foi cadastrado anteriormente." ? "Cadastro já existe!" : "Erro",
    description: error_message ? error_message : "Algo aconteceu de errado",
    closeModal: closeModalFuncionario,
  };

  const config = {
    title: "Atenção",
    description: "Está ação não pode ser desfeita, deseja continuar?",
    onClick: () => {
      const dados = {
        ...vincular_dados,
        has_updated: 1,
        vendedor: vincular_dados?.dados?.vendedor,
      };
      dispatch(VinculoCreators.requestVinculo(dados, false));
      dispatch(ModalCreators.clear_dados());
      dispatch(ModalCreators.modal_aprovar_vinculo());
    },
    closeModal: () => {
      dispatch(ModalCreators.clear_dados());
      dispatch(ModalCreators.modal_aprovar_vinculo());
    },
  };

  function closeModalFuncionario() {
    setModalConfirCadastroFuncionario(false);
  }

  useEffect(() => {
    if (data.length) {
      setGerente(
        data.filter((item) => {
          item.cpf = formataCPF(item.documento);
          return item;
        })
      );

      setVendedor(
        data.filter((item) => {
          item.cpf = formataCPF(item.documento);
          return item;
        })
      );
    }
  }, [data]);

  useEffect(() => {
    if (error_message) {
      setModalConfirCadastroFuncionario(true);
    }
  }, [error_message]);

  switch (tipo) {
    case "financiamento":
    case "cartao_mit":
      return (
        <>
          <Modal
            component={ModalVinculo}
            active={modal_vinculo_vendedor}
            itens={vendedor}
            title={"Vincule o Vendedor"}
            placeholder="Digite o CPF do vendedor"
            tipo="vendedor"
            etapa="1"
          />

          <Modal
            component={ModalVinculo}
            active={modal_vincular_gerente}
            itens={gerente}
            title={"Vincule o Gerente / F&I :"}
            placeholder="Digite o CPF do Gerente / F&I :"
            tipo="gerente"
            etapa="2"
          />

          <Modal
            tipo={tipo}
            component={ModalConfirmaVinculo}
            active={modal_confirma_vinculo}
          />

          <Modal component={Finalizar} active={modal_vinculo_finaliza} />
          <Modal component={ErroFinalizar} active={modal_erro_vincular} />

          <Modal component={ModalVisualiza} active={modal_vinculo_visualizar} />

          <Modal
            component={ModalContrato}
            active={modal_aprovar_vinculo}
            rest={config}
          />

          <Modal
            component={ModalAction}
            active={modalConfirCadastroFuncionario}
            rest={configCreateFuncionario}
          />
        </>
      );

    case "seguro":
      return (
        <>
          <Modal
            component={ModalVinculoSeguro}
            active={modal_vinculo_vendedor_seguro}
            itens={data}
            title={"Vincule o colaborador"}
            placeholder="Digite o CPF do vendedor"
            tipo="vendedor"
            etapa="1"
          />

          <Modal
            component={ModalConfirmaVinculoSeguro}
            active={modal_confirma_vinculo_seguro}
          />

          <Modal component={Alerta} active={modal_alerta_user_qtd_voucher} />

          <Modal
            component={ModalVisualiza}
            active={modal_vinculo_visualizar}
            // active={modal_confirma_visualizar}
          />

          <Modal component={Finalizar} active={modal_vinculo_finaliza} />

          <Modal
            component={ModalAction}
            active={modalConfirCadastroFuncionario}
            rest={configCreateFuncionario}
          />
        </>
      );

    case "speed":
      return (
        <>
          <Modal
            component={ModalVinculoSeguro}
            active={modal_vinculo_vendedor_seguro}
            itens={data}
            title={"Vincule o colaborador"}
            placeholder="Digite o CPF do vendedor"
            tipo="vendedor"
            etapa="1"
          />

          <Modal
            component={ModalConfirmaVinculoSeguro}
            active={modal_confirma_vinculo_seguro}
          />

          <Modal component={Alerta} active={modal_alerta_user_qtd_voucher} />

          <Modal
            component={ModalVisualiza}
            active={modal_vinculo_visualizar}
            // active={modal_confirma_visualizar}
          />

          <Modal component={Finalizar} active={modal_vinculo_finaliza} />

          <Modal
            component={ModalAction}
            active={modalConfirCadastroFuncionario}
            rest={configCreateFuncionario}
          />
        </>
      );

    default:
      return <>'-'</>;
  }
}

export default Tipomodal;
